import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";

export default function BlogPost({ data }) {
	const post = data?.wpPost;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.title}
				description={post.blogSeo.metaDescription}
				openGraph={{
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post.title}`,
					description: post.blogSeo.metaDescription,
					type: "article",
				}}
			/>
			<Container className="py-md-7 py-4">
				<Row className="justify-content-center">
					<Col
						style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)" }}
						className=" px-0 mx-0 pb-6 bg-white"
						xl={10}
					>
						<section className="pt-6 px-lg-6 px-3">
							<Row>
								<Col>
									<h5 className="text-center mb-3">BLOG</h5>

									<h1 className="text-center mb-2">{post.title}</h1>
									<hr
										className="bg-primary mx-auto mt-0"
										style={{ height: "2px", width: "6rem" }}
									/>
								</Col>
							</Row>

							<Row>
								<Col>
									<div className="blog-post-content">{parse(post.content)}</div>
									<h3>Share this:</h3>
									<ul className="ps-0">
										<li className="d-inline-block me-3">
											<Link
												target="_blank"
												rel="noreferrer"
												className="fs-5 text-dark share-icon"
												to={`https://www.facebook.com/sharer/sharer.php?u=https%3A//denisbonnici.com/blog/${post.slug}`}
											>
												<FaFacebookSquare />
											</Link>
										</li>

										<li className="d-inline-block me-3">
											<Link
												target="_blank"
												rel="noreferrer"
												className="fs-5 text-dark share-icon"
												to={`https://twitter.com/intent/tweet?text=https%3A//denisbonnici.com/blog/${post.slug}`}
											>
												<FaTwitterSquare />
											</Link>
										</li>
										<li className="d-inline-block ">
											<Link
												target="_blank"
												rel="noreferrer"
												className="fs-5 text-dark share-icon"
												to={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A//denisbonnici.com/blog/${post.slug}&title=&summary=&source=`}
											>
												<FaLinkedin />
											</Link>
										</li>
									</ul>
								</Col>
							</Row>
						</section>
						<div className="w-100 bg-primary">
							<Row className=" py-4">
								{data.previous !== null && (
									<Col className="ps-md-5 ps-4" lg={6}>
										<Link
											className="text-white fw-bold"
											to={`/blog/${data?.previous.slug}`}
										>
											<p>PREVIOUS</p>
											{data?.previous.title}
										</Link>
									</Col>
								)}
								{data.previous !== null && data.next !== null && (
									<Col className="text-end pe-md-5 pe-4 mt-4 mt-lg-0" lg={6}>
										<Link
											className="text-white ms-auto fw-bold"
											to={`/blog/${data?.next.slug}`}
										>
											<p>NEXT</p>
											{data?.next.title}
										</Link>
									</Col>
								)}
								{data.previous === null && data.next !== null && (
									<Col className="text-end pe-md-5 pe-4" lg={12}>
										<Link
											className="text-white ms-auto fw-bold"
											to={`/blog/${data?.next.slug}`}
										>
											<p>NEXT</p>
											{data?.next.title}
										</Link>
									</Col>
								)}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
}

export const blogData = graphql`
	query ($id: String!, $previousPostId: String, $nextPostId: String) {
		wpPost(id: { eq: $id }) {
			id
			slug
			title
			uri
			blogSeo {
				metaDescription
			}
			excerpt
			content
		}
		previous: wpPost(id: { eq: $previousPostId }) {
			slug
			title
		}
		next: wpPost(id: { eq: $nextPostId }) {
			slug
			title
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
